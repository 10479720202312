<template>
  <div class="p-4">
    <div class="text-xl text-center">
      Olá! Seja bem vindo ao Dicionário Manabou!
    </div>

    <div class="text-md mt-4 text-center">
      Manabou é um simples dicionário Japonês-Português com o objetivo em te ajudar a estudar a língua Japonesa.
    </div>
  </div>
</template>
